<template>
  <div class="flex">
    <AccountOutline />
  </div>
</template>

<script>
import AccountOutline from "vue-material-design-icons/AccountOutline.vue";

export default {
  name: "AccountOutlineIcon",
  components: {
    AccountOutline,
  },
};
</script>
