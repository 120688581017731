<template>
  <div class="flex">
    <SendOutline />
  </div>
</template>

<script>
import SendOutline from "vue-material-design-icons/SendOutline.vue";

export default {
  name: "SendOutlineIcon",
  components: {
    SendOutline,
  },
};
</script>
