<template>
  <div class="flex">
    <MailOutline />
  </div>
</template>

<script>
import MailOutline from "vue-material-design-icons/EmailOutline.vue";

export default {
  name: "MailOutlineIcon",
  components: {
    MailOutline,
  },
};
</script>
