<template>
  <div class="float-right w-96 mb-3 mr-1">
    <DropdownComponent v-model:selectedPassword="selectedPassword" />
  </div>
  <!-- <div>
    <DialogComponent button-text="ladida" />
  </div> -->
  <!-- {{ users }} -->
  <table class="border-collapse table-auto w-full text-sm">
    <thead>
      <tr>
        <th>
          <label
            for="searchInput"
            class="form-label"
          >Suche</label>
        </th>
        <th
          colspan="5"
          class="text-left"
        >
          <input
            id="searchInput"
            v-model="searchText"
            type="text"
            class="border-solid border-black border-1"
          >
        </th>
      </tr>
      <tr>
        <!-- <th>id</th> -->
        <th :class="tableHeaderClass">
          block_name
        </th>
        <th :class="tableHeaderClass">
          name
        </th>
        <th :class="tableHeaderClass">
          email
        </th>
        <th :class="tableHeaderClass">
          role
        </th>
        <th :class="tableHeaderClass">
          active
        </th>
        <th :class="tableHeaderClass">
          prep
        </th>
        <th :class="tableHeaderClass">
          send
        </th>
        <th :class="tableHeaderClass">
          ResPW
        </th>
        <th :class="tableHeaderClass">
          ign
        </th>
        <th :class="tableHeaderClass">
          del
        </th>
      </tr>
    </thead>
    <tbody class="bg-white dark:bg-slate-800">
      <tr
        v-for="entry in filteredUsers"
        :key="entry.id"
      >
        <!-- <td>{{ entry.id }}</td> -->
        <td :class="tableCellClass">
          {{ entry.block_name }}
        </td>
        <td :class="tableCellClass">
          {{ entry.first_name }} {{ entry.last_name }}
        </td>
        <td :class="tableCellClass">
          {{ entry.email }}
        </td>
        <td :class="tableCellClass">
          <IconSelector :icon-text="entry.user_role == 'admin' ? 'shieldAccountOutline' : 'accountOutline'" />
        </td>
        <td :class="tableCellClass">
          <button class="border-solid border-2 hover:border-blue-700 border-blue-500 py-0 px-0 rounded">
            <IconSelector
              v-if="entry.active"
              icon-text="accountCheckOutline"
              class="text-green-600"
              @click="toggleUserStatus({ id: entry.id })"
            />
            <IconSelector
              v-else
              icon-text="accountCancelOutline"
              class="text-red-600"
              @click="toggleUserStatus({ id: entry.id })"
            />
          </button>
        </td>
        <td :class="tableCellClass">
          <IconSelector
            icon-text="sendOutline"
            :class="{ 'text-red-600': !entry.prepare_for_sent, 'text-green-600': entry.prepare_for_sent }"
          />
        </td>
        <td :class="tableCellClass">
          <IconSelector
            v-if="entry.sent_for_print"
            icon-text="mailCheckOutline"
            class="text-green-600"
          />
          <IconSelector
            v-else
            icon-text="mailOffOutline"
            class="text-red-600"
          />
        </td>
        <td :class="tableCellClass">
          <button class="border-solid border-2 hover:border-blue-700 border-blue-500 py-0 px-0 rounded">
            <IconSelector
              icon-text="lockReset"
              @click="resetPassword(entry.id)"
            />
          </button>
        </td>
        <td :class="tableCellClass">
          <button class="border-solid border-2 hover:border-blue-700 border-blue-500 py-0 px-0 rounded">
            <IconSelector
              icon-text="accountOffOutline"
              @click="toggleUserIgnore({ id: entry.id })"
            />
          </button>
        </td>
        <td :class="tableCellClass">
          <button class="border-solid border-2 hover:border-blue-700 border-blue-500 py-0 px-0 rounded">
            <IconSelector
              icon-text="deleteOutline"
              @click="onDeleteUser(entry.id)"
            />
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import IconSelector from './combinedIcons/IconSelector.vue'
import DropdownComponent from './uiElements/DropdownComponent.vue'
// import DialogComponent from './uiElements/DialogComponent.vue'

export default {
  components: {
    IconSelector,
    DropdownComponent,
    // DialogComponent,
  },
  data() {
    return {
      searchText: '',
      tableHeaderClass: 'border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left',
      tableCellClass: 'border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400',
      selectedPassword: 'IchKannMirMeinEigenesPasswortNichtMerken',
      passwordList: [
        'IchKannMirMeinEigenesPasswortNichtMerken',
        'IchBinEinfachNurDumm',
        'TrumpForPresident',
        'KannIchMirSowiesoNichtMerken',
        '17Mal4Durch3Mal8',
        'OhneGehtNicht',
        'Ladida',
        'Kloskind',
        'IchBinEinKlopskind',
        'WieDoofKannManSein',
        'BusenGrabscher'
      ]
    }
  },
  computed: {
    ...mapGetters('users', {
      users: 'users',
    }),
    filteredUsers() {
      if (this.searchText != '') {
        const filterableUsers = this.users.map((e) => {
          return {
            id: e.id, searchString:
              [e.first_name, e.last_name, e.block_name, e.email].join(' ').toLowerCase()
          }
        })
        const filtered = filterableUsers.filter((e) => {
          return e.searchString.includes(this.searchText.toLowerCase())
        })

        return this.users.filter((e) => filtered.map((a) => a.id).includes(e.id))
      } else {
        return this.users
      }
    },
  },
  mounted() {
    this.getUsers()
  },
  methods: {
    ...mapActions('users', {
      getUsers: 'getUsers',
      toggleUserStatus: 'toggleUserStatus',
      setUserPassword: 'setUserPassword',
      toggleUserIgnore: 'toggleUserIgnore',
      deleteUser: 'deleteUser',
    }),
    resetPassword(userId) {
      console.log('resetPW:', userId, this.selectedPassword)
      this.setUserPassword({ userId, password: this.selectedPassword })
    },
    onDeleteUser(userId) {
      this.deleteUser({ userId })
    },
  },
}
</script>
