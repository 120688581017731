<template>
  Register Me
  <div class="bg-red-300 text-white">
    {{ getRegisterMessage }}
  </div>
  <div class="flex justify-center">
    <form>
      <table>
        <tr>
          <td class="text-right pr-3 w-60">
            <label
              for="txtFirstName"
              class="form-label"
            >Vorname</label>
          </td>
          <td>
            <input
              id="txtFirstName"
              v-model="firstName"
              type="text"
              class="form-control"
              aria-describedby="emailHelp"
            >
          </td>
        </tr>
        <tr>
          <td class="text-right pr-3 w-60">
            <label
              for="txtLastName"
              class="form-label"
            >Nachname</label>
          </td>
          <td>
            <input
              id="txtLastName"
              v-model="lastName"
              type="text"
              class="form-control"
              aria-describedby="emailHelp"
            >
          </td>
        </tr>
        <tr>
          <td class="text-right pr-3 w-60">
            <label
              for="txtBlockname"
              class="form-label"
            >Blockname</label>
          </td>
          <td>
            <input
              id="txtBlockname"
              v-model="blockName"
              type="text"
              class="form-control"
              aria-describedby="emailHelp"
            >
          </td>
        </tr>
        <tr>
          <td class="text-right pr-3 w-60">
            <label
              for="txtEmail"
              class="form-label"
            >Email</label>
          </td>
          <td>
            <input
              id="txtEmail"
              v-model="email"
              type="text"
              class="form-control"
              aria-describedby="emailHelp"
            >
          </td>
        </tr>
        <tr>
          <td class="text-right pr-3 w-60">
            <label
              for="txtPassword"
              class="form-label"
            >Passwort</label>
          </td>
          <td>
            <input
              id="txtPassword"
              v-model="password"
              type="password"
              class="form-control"
            >
          </td>
        </tr>
        <tr>
          <td class="text-right pr-3 w-60">
            <label
              for="txtPasswordCheck"
              class="form-label"
            >Passwort wiederholen</label>
          </td>
          <td>
            <input
              id="txtPasswordCheck"
              v-model="passwordCheck"
              type="password"
              class="form-control"
            >
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <button
              type="button"
              class="mr-2 mt-1 hover:bg-blue-700 bg-blue-500 text-white font-bold py-2 px-4 rounded"
              @click="register()"
            >
              Datenverarbeitung zusimmen und registrieren
            </button>
          </td>
        </tr>
      </table>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  data() {
    return {
      firstName: '',
      lastName: '',
      blockName: '',
      email: '',
      password: '',
      passwordCheck: '',
    }
  },
  computed: {
    ...mapGetters('auth', {
      getRegisterMessage: 'getRegisterMessage',
    }),
  },
  methods: {
    ...mapActions('auth', {
      registerUser: 'registerUser',
    }),
    ...mapMutations('auth', {
      setRegisterMessage: 'setRegisterMessage',
    }),
    register() {
      this.setRegisterMessage('')
      if (this.firstName && this.lastName && this.email && this.password && this.passwordCheck && (this.password == this.passwordCheck)) {
        this.registerUser({
          firstName: this.firstName,
          lastName: this.lastName,
          blockName: this.blockName,
          email: this.email,
          password: this.password,
        })
      } else if (this.password != this.passwordCheck) {
        this.setRegisterMessage('Passwörten stimmen nicht überein!')
      } else {

        this.setRegisterMessage('Alle Felder müssen ausgefüllt sein!')
      }
    }
  }
}
</script>
