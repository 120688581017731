<template>
  <div class="flex justify-center">
    <div class="w-[500px]">
      <!-- <h4>Login Form</h4> -->
      <form>
        <div class="mb-3">
          <label
            for="txtEmail"
            class="form-label"
          >
            Deine E-Mail-Adresse
          </label>
          <input
            id="txtEmail"
            v-model="email"
            type="text"
            class="form-control"
            aria-describedby="emailHelp"
          >
        </div>
        <div class="mb-3">
          <label
            for="txtPassword"
            class="form-label"
          >Dein selbst gewähltes Passwort</label>
          <input
            id="txtPassword"
            v-model="password"
            type="password"
            class="form-control"
            @keyup.enter="login()"
          >
        </div>
        <button
          type="button"
          class="mr-2 hover:bg-blue-700 bg-blue-500 text-white font-bold py-2 px-4 rounded"
          @click="login()"
        >
          Anmelden
        </button>
      </form>
    </div>
  </div>

  <div v-if="false">
    <!-- Overlay element -->
    <div
      id="overlay"
      class="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60"
    />

    <!-- The dialog -->
    <div
      id="dialog"
      class=" fixed z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-96 bg-white rounded-md px-8 py-6 space-y-5 drop-shadow-lg"
    >
      <h1 class="text-2xl font-semibold">
        Fehler
      </h1>
      <div class="py-5 border-t border-b border-gray-300">
        <p>Da ging was mit deiner Anmeldung schief. Bitte prüfen deine Email-Adresse und dein Passwort.</p>
      </div>
      <div class="flex justify-end">
        <!-- This button is used to close the dialog -->
        <button
          id="close"
          class="px-5 py-2 bg-indigo-500 hover:bg-indigo-700 text-white cursor-pointer rounded-md"
          @click="onCloseDialog()"
        >
          Close
        </button>
      </div>
    </div>
  </div>
  <DialogComponent
    dialog-title="Fehler"
    dialog-text="Da ging was mit deiner Anmeldung schief. Bitte prüfen deine Email-Adresse und dein Passwort."
    :show-dialog="showDialog"
    @on-close-dialog="showDialog = false"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DialogComponent from './uiElements/DialogComponent.vue'

export default {
  components: { DialogComponent },
  data() {
    return {
      email: '',
      password: '',
      showDialog: false,
    };
  },
  computed: {
    ...mapGetters('auth', {
      getLoginApiStatus: 'getLoginApiStatus',
    }),
  },
  methods: {
    ...mapActions('auth', {
      actionLoginApi: 'loginApi',
    }),
    onCloseDialog() {
      this.showDialog = false;
    },
    async login() {
      const payload = {
        email: this.email,
        password: this.password,
      };
      await this.actionLoginApi(payload);
      if (this.getLoginApiStatus == 'success') {
        this.$router.push('/home');
      }
      else {
        // alert('failed')
        this.showDialog = true;
      }
    },
  },
}
</script>
