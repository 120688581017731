<template>
  <div class="flex">
    <ChartTree />
  </div>
</template>

<script>
import ChartTree from "vue-material-design-icons/ChartTree.vue";

export default {
  name: "ChartTreeIcon",
  components: {
    ChartTree,
  },
};
</script>
