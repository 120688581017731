<template>
  <div class="flex">
    <AccountCheckOutline />
  </div>
</template>

<script>
import AccountCheckOutline from 'vue-material-design-icons/AccountCheckOutline.vue'

export default {
  name: 'AccountCheckOutlineIcon',
  components: {
    AccountCheckOutline,
  },
}
</script>
