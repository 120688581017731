<template>
  <div class="flex">
    <UnfoldMoreHorizontal />
  </div>
</template>

<script>
import UnfoldMoreHorizontal from "vue-material-design-icons/UnfoldMoreHorizontal.vue";

export default {
  name: "UnfoldMoreHorizontalIcon",
  components: {
    UnfoldMoreHorizontal,
  },
};
</script>
