<template>
  <div class="flex">
    <MagnifyPlusOutline />
  </div>
</template>

<script>
import MagnifyPlusOutline from "vue-material-design-icons/MagnifyPlusOutline.vue";

export default {
  name: "MagnifyPlusOutlineIcon",
  components: {
    MagnifyPlusOutline,
  },
};
</script>
