<template>
  <div v-if="showDialog">
    <!-- Overlay element -->
    <div
      id="overlay"
      class="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60"
    />

    <!-- The dialog -->
    <div
      id="dialog"
      class=" fixed z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-96 bg-white rounded-md px-8 py-6 space-y-5 drop-shadow-lg"
    >
      <h1 class="text-2xl font-semibold">
        {{ dialogTitle }}
      </h1>
      <div class="py-5 border-t border-b border-gray-300">
        <p>{{ dialogText }}</p>
      </div>
      <div class="flex justify-end">
        <!-- This button is used to close the dialog -->
        <button
          id="close"
          class="px-5 py-2 bg-indigo-500 hover:bg-indigo-700 text-white cursor-pointer rounded-md"
          @click="onCloseDialog()"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DialogComponent',
  props: {
    dialogTitle: {
      type: String,
      default: '',
      required: false,
    },
    dialogText: {
      type: String,
      default: '',
      required: false,
    },
    buttonText: {
      type: String,
      default: 'OK',
      required: false,
    },
    showDialog: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  emits: ['onCloseDialog'],
  data() {
    return {
    }
  },
  methods: {
    onCloseDialog() {
      this.$emit('onCloseDialog', false)
    },
  },
}
</script>
