<template>
  <div class="flex">
    <MagnifyMinusOutline />
  </div>
</template>

<script>
import MagnifyMinusOutline from "vue-material-design-icons/MagnifyMinusOutline.vue";

export default {
  name: "MagnifyMinusOutlineIcon",
  components: {
    MagnifyMinusOutline,
  },
};
</script>
