<template>
  <div class="flex">
    <AccountOffOutline />
  </div>
</template>

<script>
import AccountOffOutline from "vue-material-design-icons/AccountOffOutline.vue";

export default {
  name: "AccountOffOutlineIcon",
  components: {
    AccountOffOutline,
  },
};
</script>
