<template>
  <div v-if="getAuthData.sentForPrint">
    Du hast deinen Clubausweis bereits bestellt.
  </div>
  <div v-else>
    <!-- {{ getAuthData }} -->
    <div>
      <p class="ml-1 mr-1 mb-2">
        Auf dieser Seite werden einige Daten von dir zusammen gesammelt, damit wir deinen Clubausweis drucken können.
      </p>
      <p class="ml-1 mr-1 mb-2">
        Alle Daten werden ausschließlich dazu verwendet, um den Ausweis zu erstellen. Lediglich deine Adressdaten werden
        wir mit ggf. mit unseren Datenbestand abgleichen. Daten die anfallen damit dein Browser das hier alles anzeigen
        kann werden automatisch in so genannten Logfiles (also Kommunikations-Protokolle) hinterlegt. Das macht jede
        Internetseite so und interessiert uns nur, wenn es zu schwerwiegenden Fehlern kommen sollte. Sonst werden diese
        Logfiles ungesehen regelmäßig gelöscht. Was mit deinen Daten alles passiert und wie alles funktioniert, kannst du
        <button
          class="hover:bg-blue-700 bg-blue-500 text-white font-bold py-0 px-0 rounded"
          @click="showMoreDescription = !showMoreDescription"
        >
          hier
        </button>
        nachlesen.
      </p>
    </div>
    <div
      v-if="showMoreDescription"
      class="mt-2 text-left border-solid border-1 border-gray-200 ml-3 mr-3"
    >
      <p class="ml-1 mr-1 mb-2">
        Im ersten Schritt kannst du von dir ein Bild hochladen. Darauf solltest du gut und
        vorallem frontal, also von vorne, zu sehen sein.
      </p>
      <p class="ml-1 mr-1 mb-2">
        Das Bild wird dir dann angezeigt. Auf dem Bild wird ein Rahmen eingeblendet, den du so
        über dein Gesicht durch die Pfeil-Buttons bzw. durch größer oder kleiner stellen platzieren sollst, dass du wie
        auf einem Passbild gut zu erkennen bist.
      </p>
      <p class="ml-1 mr-1 mb-2">
        Lediglich dieser Ausschnitt wird später zum drucken verwendet werden.
      </p>
      <p class="ml-1 mr-1 mb-2">
        Dein voller Name und Blockname sollte bereits ausgefüllt sein. Prüfe bitte, ob sich nicht
        noch ein Schriebfehler eingeschlichen hat. Wenn deu eigentich keinen richtigen Blocknamen hast, dann tragen hier
        bitte deien im Blockumfeld gängigen Rufnamen ein.
      </p>
      <p class="ml-1 mr-1 mb-2">
        Wenn so weit alles stimmt, kannst du auf "Daten absenden" klicken. Dein Bildausschnitt,
        Name und Blockname sowie eine mit deinen Account eindeutige Nummer werden an den Server gesendet. Dort wird dein
        Bild zwischengespeichert, auf eine für den Druck benötigte Größe gebracht und ebenfalls zwischengespeichert.
      </p>
      <p class="ml-1 mr-1 mb-2">
        Nach dem Absenden erhälst du dann eine Übersicht, wie dein Ausweis wohl aussehen wird.
        Zusätzlich erstellt der Server einen QR-Code. Darin ist deine Eindeutlige verschlüsselt hinterlegt. Mit diesen
        QR-Code wollen wir die Grundlage schaffen, den Clubausweis weiteren Funktionalitäten für und in dem Block für zu
        schaffen. Die Erstellung des QR-Codes ist freiwillig und kann abgewählt werden. Was und ob der QR-Code in Zukunft
        Verwendung finden wird steht noch nicht fest, da sind wir noch in der Ideenfindung.
      </p>
      <p class="ml-1 mr-1 mb-2">
        Ein weitere Auswahlpunkt ist, ob du deinen Ausweis selbst im Block abholen möchtest oder
        ob er dir zugeschickt werden soll. Wenn der Ausweis per Post kommen soll, geb in das Textfeld bitte deine
        komplette Adresse so an, wie in einem Brief (auch mit Namen), dann lässt sich das alles etwas einfacher und
        zügiger verarbeiten.
      </p>
      <p class="ml-1 mr-1 mb-2">
        Sobald alles geklappt hat, wird auf dem Server, der diese Seite gerade darstellt, alle
        deine zwischengespeicherten Daten wieder gelöscht, das erfolgt in regelmäßigen Abständen.
      </p>
      <p class="ml-1 mr-1 mb-2">
        Über die url <a
          class="text-blue-500 font-bold"
          href="https://block.newspeak-domain.de/delete-me"
        >
          https://block.newspeak-domain.de/delete-me
        </a> kannst du unter der Angabe deiner
        Email-Adresse einen Link anfordern, um deinen Account zu löschen. Dieser wird dir dann zugeschickt. Deine Daten
        werden dann gelöscht. Für die Anfangszeit wird deine Email-Adresse in verschlüsselter Form (wir können das dann
        auch nicht mehr einfach so erkennen) gespeichert werden. Das soll verhindern, dass gelöschte Accounts gleich
        wieder angelegt werden können.
      </p>
      <p class="ml-1 mr-1 mb-2">
        Sollte alles klappen, bekommst du dann Post vom Block mit deinem frisch gedruckten
        Ausweis. Wenn du den Ausweis im Block abholen möchtest, beachte bitte, dass wahrscheinlich zu beginn recht viele
        ihren neuen Clubausweis bestellen werden und sich der ganze Ablauf erstmal einspielen muss. Mit anderen Worten
        Donnerstag Mittag bestellen bedeutet nicht, dass der Ausweis Freitag Abend schon fertig ist!
      </p>
    </div>
    <input
      ref="uploadImageValueRef"
      type="file"
      accept="image/jpeg"
      @change="uploadImage"
    >
    <button
      class="hover:bg-blue-700 bg-blue-500 text-white font-bold py-0 px-0 rounded"
      @click="clearUploadImage()"
    >
      <IconSelector :icon-text="'trashCanOutline'" />
    </button>
    <table>
      <tr v-if="sumPositions != 0">
        <td>
          <button
            class="hover:bg-blue-700 bg-blue-500 text-white font-bold py-0 px-0 rounded"
            @click="subDeltaXsubY()"
          >
            <IconSelector :icon-text="'arrowTopLeft'" />
          </button>
        </td>
        <td>
          <button
            class="hover:bg-blue-700 bg-blue-500 text-white font-bold py-0 px-0 rounded"
            @click="subDeltaY()"
          >
            <IconSelector :icon-text="'arrowUp'" />
          </button>
        </td>
        <td>
          <button
            class="hover:bg-blue-700 bg-blue-500 text-white font-bold py-0 px-0 rounded"
            @click="addDeltaXsubY()"
          >
            <IconSelector :icon-text="'arrowTopRight'" />
          </button>
        </td>
      </tr>
      <tr>
        <td>
          <button
            v-if="sumPositions != 0"
            class="hover:bg-blue-700 bg-blue-500 text-white font-bold py-0 px-0 rounded"
            @click="subDeltaX()"
          >
            <IconSelector :icon-text="'arrowLeft'" />
          </button>
        </td>
        <td>
          <canvas
            id="c"
            ref="canvas"
            style="max-width: 700px"
          />
        </td>
        <td>
          <button
            v-if="sumPositions != 0"
            class="hover:bg-blue-700 bg-blue-500 text-white font-bold py-0 px-0 rounded"
            @click="addDeltaX()"
          >
            <IconSelector :icon-text="'arrowRight'" />
          </button>
        </td>
      </tr>
      <tr v-if="sumPositions != 0">
        <td>
          <button
            class="hover:bg-blue-700 bg-blue-500 text-white font-bold py-0 px-0 rounded"
            @click="subDeltaXaddY()"
          >
            <IconSelector :icon-text="'arrowBottomLeft'" />
          </button>
        </td>
        <td>
          <button
            class="hover:bg-blue-700 bg-blue-500 text-white font-bold py-0 px-0 rounded"
            @click="addDeltaY()"
          >
            <IconSelector :icon-text="'arrowDown'" />
          </button>
        </td>
        <td>
          <button
            class="hover:bg-blue-700 bg-blue-500 text-white font-bold py-0 px-0 rounded"
            @click="addDeltaXaddY()"
          >
            <IconSelector :icon-text="'arrowBottomRight'" />
          </button>
        </td>
      </tr>
      <tr v-if="sumPositions != 0">
        <td />
        <td>
          <button
            class="hover:bg-blue-700 bg-blue-500 text-white font-bold py-0 px-0 rounded"
            @click="subZoom()"
          >
            <IconSelector :icon-text="'magnifyPlusOutline'" />
          </button>&nbsp;
          <button
            class="hover:bg-blue-700 bg-blue-500 text-white font-bold py-0 px-0 rounded"
            @click="addZoom()"
          >
            <IconSelector :icon-text="'magnifyMinusOutline'" />
          </button>
        </td>
        <td />
      </tr>
    </table>
    <form v-if="sumPositions != 0">
      <div class="mb-3">
        <label
          for="txtName"
          class="form-label"
        >
          Name
        </label>
        <input
          id="txtName"
          v-model="name"
          type="text"
          class="form-control"
          aria-describedby="emailHelp"
        >
        <p v-if="errors.name">
          {{ errors.name }}
        </p>
      </div>
      <div class="mb-3">
        <label
          for="txtBlockname"
          class="form-label"
        >
          Blockname
        </label>
        <input
          id="txtBlockname"
          v-model="blockname"
          type="text"
          class="form-control"
          aria-describedby="emailHelp"
        >
        <p v-if="errors.blockname">
          {{ errors.blockname }}
        </p>
      </div>
      <div class="mb-3">
        <label
          for="txtMemberSince"
          class="form-label"
        >
          Mitglied seit
        </label>
        <input
          id="txtMemberSince"
          v-model="memberSince"
          type="text"
          class="form-control"
          aria-describedby="memberSinceHelp"
        >
      </div>
      <div>
        <input
          id="withQrCode"
          v-model="withQrCode"
          type="checkbox"
          name="withQrCode"
          :style="{
            height: '25px', width: '25px', 'background-color': 'bg-blue-500'
          }"
        >
        <label
          class="ml-2"
          for="withQrCode"
        >
          QR-Code erstellen
        </label><br>
      </div>

      <button
        type="button"
        class="mr-2 hover:bg-blue-700 bg-blue-500 text-white font-bold py-2 px-4 rounded"
        @click="sendData()"
      >
        Daten absenden
      </button>
    </form>
    <hr>
    <div
      v-if="getUploadPicData"
      class="pl-3"
    >
      OUTPUT AREA
      <!-- 8,56cm lang und 5,398 cm breit -->
      <!-- 2 1⁄8 auf 3 3⁄8 radius 1⁄8 -->
      <!-- 2,125 3,375 radius 0.125-->
      <table>
        <tr>
          <td v-if="false">
            <div
              class="relative"
              :style="{ width: '337.5px', height: '212.5px', border: '1px solid black', 'border-radius': '12.5px' }"
            >
              <img
                class="float-right w-[100px] mr-[20px] mt-[20px]"
                :src="getUploadPicData.fittedImg"
              >
              <div class="text-left ml-[10px] mt-[20px]">
                Clubausweis
              </div>
              <div class="text-left ml-[10px] mt-[20px]">
                {{ getUploadPicData.name }}
              </div>
              <div class="text-left ml-[10px] mt-[10px]">
                {{ getUploadPicData.blockname }}
              </div>
              <div class="absolute bottom-0 right-0 ...">
                <!-- <p>Absolute child</p> -->
                <img
                  class="w-[40px] mr-[20px] mb-[10px] float-right float-end"
                  src="../assets/b17-android-icon-192x192.png"
                >
              </div>
            </div>
            <br>

            <div
              class="relative"
              :style="{ width: '337.5px', height: '212.5px', border: '1px solid black', 'border-radius': '12.5px' }"
            >
              <img
                v-if="withQrCode"
                class="float-right w-[100px] mr-[20px] mt-[20px]"
                :src="getUploadPicData.qr"
              >
            </div>
          </td>
          <td>
            <div>
              <!-- <div>
                {{ withQrCode }}
                <input v-model="withQrCode" type="checkbox" id="withQrCode" name="withQrCode" :style="{
                  height: '25px', width: '25px', 'background-color': 'bg-blue-500'
                }">
                <label class="ml-2" for="withQrCode">QR-Code erstellen</label><br>
              </div> -->
              <div>
                <input
                  id="selfCollector"
                  v-model="selfCollector"
                  type="checkbox"
                  name="selfCollector"
                  :style="{
                    height: '25px', width: '25px', 'background-color': 'bg-blue-500'
                  }"
                >
                <label
                  class="ml-2"
                  for="selfCollector"
                >
                  Selbstabholer, bitte die Benachrichtigung aus dem Block abwarten, wann dein Ausweis abholbereit ist!
                </label><br>
              </div>
              <p v-if="!selfCollector">
                Adresse, wenn per Post zugestellt werden soll:<br>
                Bitte komplett mit Name, Straße und Hausnummer, Postleitzahl und Ort<br>
                Das erleichtert uns ungemein den Versand, Danke!
              </p>
              <div
                v-if="!selfCollector"
                class="border-solid border-1 border-black"
              >
                <textarea
                  v-model="address"
                  rows="5"
                  class="w-[300px] bg-gray-200"
                />
              </div>
            </div>
          </td>
        </tr>
      </table>

      <!-- <img :src="'/downloadPicFront/' + getAuthData.userId" > -->
      <img
        width="500"
        :src="getFrontPic"
      >
      <br>
      <img
        width="500"
        :src="getBackPic"
      >
      <!-- {{ getFrontPic }} -->
      <br>
      <p>
        Mit "Jetzt bestellen" werden deine Daten an den Block gesendet und zeitnah dein neuer Clubausweis gedruck.
      </p>
      <p>
        Ein erneutes ausfüllen und nochmal drucken lassen ist nicht möglich. Also prüfe bitte deine Daten genau, ob alles stimmt.
      </p>
      <p>
        Zur Bestätigung das alles verschickt wurde, bekommst du die gleiche Mail wie auch der Block.
      </p>
      <button
        type="button"
        :disabled="!selfCollector && address == ''"
        class="mr-2 hover:bg-blue-700 bg-blue-500 text-white font-bold py-2 px-4 rounded"
        :class="{ 'opacity-50 cursor-not-allowed': !selfCollector && address == '' }"
        @click="onOrderClubcard()"
      >
        Jetzt bestellen
      </button>
      <br>
      <br>
      <br>
      <br>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import IconSelector from './combinedIcons/IconSelector.vue'

export default {
  components: {
    IconSelector,
  },
  data() {
    return {
      showMoreDescription: false,
      address: '',
      selfCollector: false,
      withQrCode: true,
      name: '',
      blockname: '',
      memberSince: '',
      errors: {
        name: '',
        blockname: '',
        memberSince: '',
      },

      // Image
      ctx: null,
      canvas: null,
      img: null,
      imageUrl: '',

      height: 200,
      width: 400,
      zoomStep: 5,
      zoom: 15,

      deltaStep: 10,
      deltaX: 0,
      deltaY: 0,

      tWidth: 0,
      tHeight: 0,
      x1: 0,
      x2: 0,
      y1: 0,
      y4: 0,
      y2: 0,
      y3: 0,
      x3: 0,
      x4: 0,
    }
  },
  computed: {
    ...mapGetters('picture', {
      getUploadPicData: 'getUploadPicData',
      getFrontPic: 'getFrontPic',
      getBackPic: 'getBackPic',
    }),
    ...mapGetters('auth', {
      getAuthData: 'getAuthData',
    }),
    sumPositions() {
      return this.x1 + this.x2 + this.x3 + this.x4 + this.y1 + this.y2 + this.y3 + this.y4
    }
  },
  mounted() {
    this.clearUploadPicData()
    if (this.getAuthData.sentForPrint) {

    } else {
      this.canvas = document.getElementById('c')
      this.canvas.height = 0
      this.canvas.width = 0
      this.ctx = this.canvas.getContext('2d')
      this.img = document.createElement('img')
    }
  },
  methods: {
    ...mapActions('picture', {
      uploadPic: 'uploadPic',
      clearUploadPicData: 'clearUploadPicData',
      downloadFront: 'downloadFront',
      downloadBack: 'downloadBack',
      orderClubcard: 'orderClubcard',
    }),
    validateName() {
      this.errors.name = this.name.trim().length >= 1 ? '' : 'Name must be at least 1 character.'
    },
    validateBlockname() {
      this.errors.blockname = this.blockname.trim().length >= 1 ? '' : 'Blockname must be at least 1 character.'
    },
    addZoom() {
      if (this.zoom < 90) {
        this.zoom += this.zoomStep
        this.drawRect()
      }
    },
    subZoom() {
      const zzz = this.height * ((this.zoom - this.zoomStep) / 100)
      const tHeight = this.height - (zzz)
      const y2 = Math.floor(this.y1 + tHeight)
      const y1 = Math.floor(zzz / 2) + this.deltaY

      const tWidth = Math.floor(3.5 * tHeight / 4.5)

      const x1 = Math.floor((this.width - tWidth) / 2) + this.deltaX
      const x3 = Math.floor(x1 + tWidth)

      if (this.zoom > 0 && y2 <= this.height && y1 >= 0 && x1 > 0 && x3 <= this.width) {
        this.zoom -= this.zoomStep
        this.drawRect()
      }
    },
    addDeltaX() {
      if (this.width > this.x4 + this.deltaStep) {
        this.deltaX += this.deltaStep
      }
      this.drawRect()
    },
    addDeltaY() {
      if (this.height > this.y2 + this.deltaStep) {
        this.deltaY += this.deltaStep
      }
      this.drawRect()
    },
    subDeltaX() {
      if (this.x1 > this.deltaStep) {
        this.deltaX -= this.deltaStep
      }
      this.drawRect()
    },
    subDeltaY() {
      if (this.y1 - this.deltaStep >= 0) {
        this.deltaY -= this.deltaStep
      }
      this.drawRect()
    },
    subDeltaXaddY() {
      this.subDeltaX()
      this.addDeltaY()
    },
    addDeltaXsubY() {
      this.addDeltaX()
      this.subDeltaY()
    },
    addDeltaXaddY() {
      this.addDeltaX()
      this.addDeltaY()
    },
    subDeltaXsubY() {
      this.subDeltaX()
      this.subDeltaY()
    },

    drawRect() {
      const zzz = this.height * (this.zoom / 100)
      this.tHeight = this.height - (zzz)
      this.tWidth = Math.floor(3.5 * this.tHeight / 4.5)

      this.x1 = Math.floor((this.width - this.tWidth) / 2) + this.deltaX
      this.x2 = this.x1
      this.y1 = Math.floor(zzz / 2) + this.deltaY
      this.y4 = this.y1
      this.y2 = Math.floor(this.y1 + this.tHeight)
      this.y3 = this.y2
      this.x3 = Math.floor(this.x1 + this.tWidth)
      this.x4 = this.x3

      // x1,y1  --  x4,y4
      //   |          |
      //   |          |
      //   |          |
      //   |          |
      // x2,y2  --  x3,y3

      this.ctx.clearRect(0, 0, this.width, this.height)
      this.ctx.drawImage(this.img, 0, 0, this.width, this.height)

      this.ctx.lineWidth = Math.ceil(this.width / 100)

      this.ctx.beginPath()
      this.ctx.moveTo(this.x1, this.y1)
      this.ctx.lineTo(this.x2, this.y2)
      this.ctx.lineTo(this.x3, this.y3)
      this.ctx.lineTo(this.x4, this.y4)
      this.ctx.closePath()

      this.ctx.stroke()
    },
    clearUploadImage() {
      this.$refs.uploadImageValueRef.value = null
      this.x1 = 0
      this.x2 = 0
      this.x3 = 0
      this.x4 = 0
      this.y1 = 0
      this.y2 = 0
      this.y3 = 0
      this.y4 = 0
      this.ctx.clearRect(0, 0, this.width, this.height)
      this.canvas.height = 0
      this.canvas.width = 0
    },
    uploadImage(event) {
      if (this.img == null) {
        this.img = document.createElement('img')
      }
      this.blockname = this.getAuthData.blockName
      this.name = this.getAuthData.userName
      this.memberSince = this.getAuthData.memberSince

      const vm = this
      const input = event.target
      this.uploadImageValue = input

      if (input.files && input.files[0]) {
        const reader = new FileReader()

        reader.onload = function (e) {
          vm.img.src = e.target.result
        }

        reader.readAsDataURL(input.files[0])

        vm.img.onload = function () {
          vm.ctx.drawImage(vm.img, 0, 0)
          vm.height = vm.img.height
          vm.width = vm.img.width

          vm.canvas.height = vm.height
          vm.canvas.width = vm.width

          vm.drawRect()
        }
      }
    },
    renderPart() {
      var newCanvas = document.createElement('canvas')
      var newContext = newCanvas.getContext('2d')
      var rect = {
        x: this.x1,
        y: this.y1,
        width: this.x4 - this.x1,
        height: this.y2 - this.y1
      }
      newCanvas.width = rect.width
      newCanvas.height = rect.height
      var originalCanvas = document.getElementById('c')
      var imageData = originalCanvas.getContext('2d').getImageData(rect.x, rect.y, rect.width, rect.height)
      newContext.putImageData(imageData, 0, 0)
      this.download(newCanvas)
    },
    async sendData() {
      this.validateName()
      this.validateBlockname()
      if (!this.errors.name && !this.errors.blockname) {
        var newCanvas = document.createElement('canvas')
        var newContext = newCanvas.getContext('2d')
        var rect = {
          x: this.x1,
          y: this.y1,
          width: this.x4 - this.x1,
          height: this.y2 - this.y1
        }
        newCanvas.width = rect.width
        newCanvas.height = rect.height
        var originalCanvas = document.getElementById('c')
        var imageData = originalCanvas.getContext('2d').getImageData(rect.x, rect.y, rect.width, rect.height)
        newContext.putImageData(imageData, 0, 0)

        this.imageUrl = newCanvas.toDataURL('image/png')

        await this.uploadPic({
          userId: this.getAuthData.userId,
          picData: this.imageUrl,
          name: this.name.trim(),
          blockname: this.blockname.trim(),
          memberSince: this.memberSince,
          withQrCode: this.withQrCode,
        })
        this.downloadFront({ userId: this.getAuthData.userId })
        this.downloadBack({ userId: this.getAuthData.userId, withQrCode: this.withQrCode })
      }
    },
    async onOrderClubcard() {
      await this.orderClubcard({
        userId: this.getAuthData.userId,
        addressData: this.address,
        selfCollector: this.selfCollector,
        withQrCode: this.withQrCode,
        realname: this.name.trim(),
        blockname: this.blockname.trim(),
        email: this.getAuthData.email,
      })
    },
    download(newCanvas) {
      this.imageUrl = newCanvas.toDataURL('image/png')

      this.uploadPic({ picData: this.imageUrl })

      var link = document.createElement('a')
      link.href = this.imageUrl
      link.download = 'image.png'

      link.click()
    },
  }
}
</script>
