<template>
  <div class="flex">
    <MailCheckOutline />
  </div>
</template>

<script>
import MailCheckOutline from "vue-material-design-icons/EmailCheckOutline.vue";

export default {
  name: "MailCheckOutlineIcon",
  components: {
    MailCheckOutline,
  },
};
</script>
