<template>
  <div class="flex">
    <ShieldAccountOutline />
  </div>
</template>

<script>
import ShieldAccountOutline from "vue-material-design-icons/ShieldAccountOutline.vue";

export default {
  name: "ShieldAccountOutlineIcon",
  components: {
    ShieldAccountOutline,
  },
};
</script>
