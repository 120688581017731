<template>
  <div class="flex">
    <AccountSettingsOutline />
  </div>
</template>

<script>
import AccountSettingsOutline from "vue-material-design-icons/AccountSettingsOutline.vue";

export default {
  name: "AccountSettingsOutlineIcon",
  components: {
    AccountSettingsOutline,
  },
};
</script>
