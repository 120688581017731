<template>
  <div>
    <CoffeeIcon v-if="iconText == 'coffee'" />
    <CoffeeIcon v-else-if="iconText == 'break'" />
    <CalendarArrowLeftIcon v-else-if="iconText == 'calendarArrowLeft'" />
    <HalfHolidayIcon v-else-if="iconText == 'halfHoliday'" />
    <HomeOutlineIcon v-else-if="iconText == 'homeOutline'" />
    <LogoutVariantIcon v-else-if="iconText == 'logoutVariant'" />
    <WorkdayIcon v-else-if="iconText == 'workday'" />
    <CalendarArrowRightIcon v-else-if="iconText == 'calendarArrowRight'" />
    <CoffeeOffIcon v-else-if="iconText == 'coffeeOff'" />
    <HalfVacationIcon v-else-if="iconText == 'halfVacation'" />
    <TrashCanOutlineIcon v-else-if="iconText == 'trashCanOutline'" />
    <CalendarTodayIcon v-else-if="iconText == 'calendarToday'" />
    <CogsIcon v-else-if="iconText == 'cogs'" />
    <HalfWorkdayIcon v-else-if="iconText == 'halfWorkday'" />
    <IllnessIcon v-else-if="iconText == 'illness'" />
    <VacationIcon v-else-if="iconText == 'vacation'" />
    <ChartTreeIcon v-else-if="iconText == 'chartTree'" />
    <FinanceIcon v-else-if="iconText == 'finance'" />
    <HolidayIcon v-else-if="iconText == 'holiday'" />
    <LogoutIcon v-else-if="iconText == 'logout'" />
    <ViewDashboardVariantOutlineIcon v-else-if="iconText == 'viewDashboardVariantOutline'" />

    <ArrowBottomLeftIcon v-else-if="iconText == 'arrowBottomLeft'" />
    <ArrowBottomRightIcon v-else-if="iconText == 'arrowBottomRight'" />
    <ArrowDownIcon v-else-if="iconText == 'arrowDown'" />
    <ArrowRightIcon v-else-if="iconText == 'arrowRight'" />
    <ArrowLeftIcon v-else-if="iconText == 'arrowLeft'" />
    <ArrowTopLeftIcon v-else-if="iconText == 'arrowTopLeft'" />
    <ArrowUpIcon v-else-if="iconText == 'arrowUp'" />
    <ArrowTopRightIcon v-else-if="iconText == 'arrowTopRight'" />
    <MagnifyPlusOutlineIcon v-else-if="iconText == 'magnifyPlusOutline'" />
    <MagnifyMinusOutlineIcon v-else-if="iconText == 'magnifyMinusOutline'" />

    <SendOutlineIcon v-else-if="iconText == 'sendOutline'" />
    <MailCheckOutlineIcon v-else-if="iconText == 'mailCheckOutline'" />
    <MailOffOutlineIcon v-else-if="iconText == 'mailOffOutline'" />
    <MailOutlineIcon v-else-if="iconText == 'mailOutline'" />

    <AccountCancelOutlineIcon v-else-if="iconText == 'accountCancelOutline'" />
    <AccountCheckOutlineIcon v-else-if="iconText == 'accountCheckOutline'" />
    <AccountSettingsOutlineIcon v-else-if="iconText == 'accountSettingsOutline'" />
    <AccountOutlineIcon v-else-if="iconText == 'accountOutline'" />
    <AccountOffOutlineIcon v-else-if="iconText == 'accountOffOutline'" />
    <ShieldAccountOutlineIcon v-else-if="iconText == 'shieldAccountOutline'" />

    <DeleteOutlineIcon v-else-if="iconText == 'deleteOutline'" />

    <LockResetIcon v-else-if="iconText == 'lockReset'" />
    <UnfoldMoreHorizontalIcon v-else-if="iconText == 'unfoldMoreHorizontal'" />

    <HelpIcon v-else-if="iconText == 'help'" />
    <HelpIcon v-else />
  </div>
</template>

<script>
import CoffeeIcon from './CoffeeIcon.vue';
import CalendarArrowLeftIcon from './CalendarArrowLeftIcon.vue';
import HalfHolidayIcon from './HalfHolidayIcon.vue';
import HomeOutlineIcon from './HomeOutlineIcon.vue';
import LogoutVariantIcon from './LogoutVariantIcon.vue';
import WorkdayIcon from './WorkdayIcon.vue';
import CalendarArrowRightIcon from './CalendarArrowRightIcon.vue';
import CoffeeOffIcon from './CoffeeOffIcon.vue';
import HalfVacationIcon from './HalfVacationIcon.vue';
import TrashCanOutlineIcon from './TrashCanOutlineIcon.vue';
import CalendarTodayIcon from './CalendarTodayIcon.vue';
import CogsIcon from './CogsIcon.vue';
import HalfWorkdayIcon from './HalfWorkdayIcon.vue';
import IllnessIcon from './IllnessIcon.vue';
import VacationIcon from './VacationIcon.vue';
import ChartTreeIcon from './ChartTreeIcon.vue';
import FinanceIcon from './FinanceIcon.vue';
import HolidayIcon from './HolidayIcon.vue';
import LogoutIcon from './LogoutIcon.vue';
import ViewDashboardVariantOutlineIcon from './ViewDashboardVariantOutlineIcon.vue';
import ArrowBottomLeftIcon from './ArrowBottomLeftIcon.vue';
import ArrowBottomRightIcon from './ArrowBottomRightIcon.vue';
import ArrowDownIcon from './ArrowDownIcon.vue';
import ArrowRightIcon from './ArrowRightIcon.vue';
import ArrowLeftIcon from './ArrowLeftIcon.vue';
import ArrowTopLeftIcon from './ArrowTopLeftIcon.vue';
import ArrowUpIcon from './ArrowUpIcon.vue';
import ArrowTopRightIcon from './ArrowTopRightIcon.vue';
import MagnifyPlusOutlineIcon from './MagnifyPlusOutlineIcon.vue';
import MagnifyMinusOutlineIcon from './MagnifyMinusOutlineIcon.vue';
import SendOutlineIcon from './SendOutlineIcon.vue';
import MailCheckOutlineIcon from './MailCheckOutlineIcon.vue';
import MailOffOutlineIcon from './MailOffOutlineIcon.vue';
import MailOutlineIcon from './MailOutlineIcon.vue';
import AccountCancelOutlineIcon from './AccountCancelOutlineIcon.vue';
import AccountCheckOutlineIcon from './AccountCheckOutlineIcon.vue';
import AccountSettingsOutlineIcon from './AccountSettingsOutlineIcon.vue';
import LockResetIcon from './LockResetIcon.vue';
import UnfoldMoreHorizontalIcon from './UnfoldMoreHorizontalIcon.vue';
import AccountOutlineIcon from './AccountOutlineIcon.vue';
import ShieldAccountOutlineIcon from './ShieldAccountOutlineIcon.vue';
import HelpIcon from './HelpIcon.vue';
import AccountOffOutlineIcon from './AccountOffOutlineIcon.vue';
import DeleteOutlineIcon from './DeleteOutlineIcon.vue';

export default {
  components: {
    CoffeeIcon,
    CalendarArrowLeftIcon,
    HalfHolidayIcon,
    HomeOutlineIcon,
    LogoutVariantIcon,
    WorkdayIcon,
    CalendarArrowRightIcon,
    CoffeeOffIcon,
    HalfVacationIcon,
    TrashCanOutlineIcon,
    CalendarTodayIcon,
    CogsIcon,
    HalfWorkdayIcon,
    IllnessIcon,
    VacationIcon,
    ChartTreeIcon,
    FinanceIcon,
    HolidayIcon,
    LogoutIcon,
    ViewDashboardVariantOutlineIcon,
    ArrowBottomLeftIcon,
    ArrowBottomRightIcon,
    ArrowDownIcon,
    ArrowRightIcon,
    ArrowLeftIcon,
    ArrowTopLeftIcon,
    ArrowUpIcon,
    ArrowTopRightIcon,
    MagnifyPlusOutlineIcon,
    MagnifyMinusOutlineIcon,
    SendOutlineIcon,
    MailCheckOutlineIcon,
    MailOffOutlineIcon,
    MailOutlineIcon,
    AccountCancelOutlineIcon,
    AccountCheckOutlineIcon,
    AccountSettingsOutlineIcon,
    LockResetIcon,
    UnfoldMoreHorizontalIcon,
    AccountOutlineIcon,
    ShieldAccountOutlineIcon,
    HelpIcon,
    AccountOffOutlineIcon,
    DeleteOutlineIcon
  },
  props: {
    iconText: {
      type: String,
      required: true
    },
  }
}
</script>
