<template>
  <div class="flex">
    <DeleteOutline />
  </div>
</template>

<script>
import DeleteOutline from "vue-material-design-icons/DeleteOutline.vue";

export default {
  name: "DeleteOutlineIcon",
  components: {
    DeleteOutline,
  },
};
</script>
