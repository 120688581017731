<template>
  <div class="flex">
    <LockReset />
  </div>
</template>

<script>
import LockReset from "vue-material-design-icons/LockReset.vue";

export default {
  name: "LockResetIcon",
  components: {
    LockReset,
  },
};
</script>
