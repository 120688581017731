<template>
  <div class="w-76 border-1">
    <Listbox v-model="selectedPasswordG">
      <div class="relative mt-1">
        <ListboxButton
          class="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
        >
          <span class="block truncate">{{ selectedPasswordG }}</span>
          <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              class="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </ListboxButton>

        <transition
          leave-active-class="transition duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions
            class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
          >
            <ListboxOption
              v-for="(pw, index) in passwordList"
              v-slot="{ active, selected }"
              :key="index"
              :value="pw"
              as="template"
            >
              <li
                :class="[
                  active ? 'bg-amber-100 text-amber-900' : 'text-gray-900',
                  'relative cursor-default select-none py-2 pl-10 pr-4',
                ]"
              >
                <span
                  :class="[
                    selected ? 'font-medium' : 'font-normal',
                    'block truncate',
                  ]"
                >{{ pw }}</span>
                <span
                  v-if="selected"
                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                >
                  <CheckIcon
                    class="h-5 w-5"
                    aria-hidden="true"
                  />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
  </div>
</template>

<script>
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions
} from '@headlessui/vue'

export default {
  name: 'DropdownComponent',
  components: {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
  },
  props: {
    selectedPassword: {
      type: String,
      required: true
    },
  },
  emits: ['update:selectedPassword'],
  data() {
    return {
      passwordList: [
        'IchKannMirMeinEigenesPasswortNichtMerken',
        'IchBinEinfachNurDumm',
        'TrumpForPresident',
        'KannIchMirSowiesoNichtMerken',
        '17Mal4Durch3Mal8',
        'OhneGehtNicht',
        'Ladida',
        'Kloskind',
        'IchBinEinKlopskind',
        'WieDoofKannManSein',
        'BusenGrabscher'
      ]
    }
  },
  computed: {
    selectedPasswordG: {
      get() {
        return this.selectedPassword
      },
      set(value) {
        this.$emit('update:selectedPassword', value)
      }
    }
  },
  methods: {},
}
</script>
