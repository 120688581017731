<template>
  Delete me
  <div class="bg-red-300 text-white">
    {{ getRegisterMessage }}
  </div>
  <div v-if="$route.params.id">
    ID: {{ $route.params.id }}
  </div>
  <form v-else>
    <table>
      <tr>
        <td class="text-right pr-3 w-60">
          <label
            for="txtEmail"
            class="form-label"
          >Email</label>
        </td>
        <td>
          <input
            id="txtEmail"
            v-model="email"
            type="text"
            class="form-control"
            aria-describedby="emailHelp"
          >
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <button
            type="button"
            class="mr-2 mt-1 hover:bg-blue-700 bg-blue-500 text-white font-bold py-2 px-4 rounded"
            @click="sendDeleteMe()"
          >
            Bereite mich zum löschen vor
          </button>
        </td>
      </tr>
    </table>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      email: ''
    }
  },
  computed: {
    ...mapGetters('auth', {
      getRegisterMessage: 'getRegisterMessage',
    }),
  },
  methods: {
    ...mapActions('auth', {
      deleteMeUser: 'deleteMeUser',
    }),
    sendDeleteMe() {
      this.deleteMeUser({
        email: this.email,
      })
    }
  }
}
</script>
