<template>
  <div class="flex">
    <Logout />
  </div>
</template>

<script>
import Logout from "vue-material-design-icons/Logout.vue";

export default {
  name: "LogoutIcon",
  components: {
    Logout,
  },
};
</script>
