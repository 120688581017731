<template>
  <div class="flex">
    <ViewDashboardVariantOutline />
  </div>
</template>

<script>
import ViewDashboardVariantOutline from "vue-material-design-icons/ViewDashboardVariantOutline.vue";

export default {
  name: "ViewDashboardVariantOutlineIcon",
  components: {
    ViewDashboardVariantOutline,
  },
};
</script>
