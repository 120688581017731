<template>
  <div class="flex">
    <AccountCancelOutline />
  </div>
</template>

<script>
import AccountCancelOutline from 'vue-material-design-icons/AccountCancelOutline.vue'

export default {
  name: 'AccountCancelOutlineIcon',
  components: {
    AccountCancelOutline,
  },
}
</script>
