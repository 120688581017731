import axios from 'axios'
import { jwtDecrypt } from '../../shared/jwtHelper'
import { tokenAlive } from '../../shared/jwtHelper'

if (process.env.NODE_ENV !== 'production') {
  axios.defaults.baseURL = 'http://localhost:3032'
  // axios.defaults.baseURL = 'http://localhost:' + process.env.VUE_APP_API_PORT;
  // } else {
  //   axios.defaults.baseURL = 'http://localhost:3002';
}

const state = () => ({
  loginApiStatus: '',
  logOut: false,
  authData: {
    token: '',
    accessToken: '',
    refreshToken: '',
    tokenExp: '',
    userId: '',
    userName: '',
    userRole: '',
    memberSince: '',
    sentForPrint: false,
    email: '',
  },
  userProfile: {
    id: 0,
    lastName: '',
    firstName: '',
    email: '',
    phone: '',
    memberSince: '',
    sentForPrint: false,
    defaultWeekWorktimeInMinutes: '',
    defaultWeekWorkdays: '',
    settings: {},
  },
  registerMessage: ''
})

const getters = {
  getLoginApiStatus(state) {
    return state.loginApiStatus
  },
  getLogout(state) {
    return state.logOut
  },
  getAuthData(state) {
    return state.authData
  },
  isTokenActive(state) {
    if (!state.authData.tokenExp) {
      return false
    }
    return tokenAlive(state.authData.tokenExp)
  },
  getUserProfile(state) {
    return state.userProfile
  },
  getRegisterMessage(state) {
    return state.registerMessage
  },
}

const actions = {
  async checkStoredToken({ commit }) {
    const access_token = localStorage.getItem('access_token')
    if (access_token != null) {
      const data = {
        access_token,
        refresh_token: '',
      }
      commit('saveTokenData', data)
      localStorage.setItem('isAuthenticated', 'true')
      commit('setLoginApiStatus', 'success')
    }
  },
  async loginApi({ commit, dispatch }, payload) {
    const response = await axios
      .post('/picapi/auth/login', payload, {
        withCredentials: false,
        credentials: 'include',
      })
      .catch((err) => {
        console.log('hierso:', err)
      })

    if (response && response.data && !response.data.error) {
      commit('saveTokenData', response.data)
      localStorage.setItem('isAuthenticated', 'true')
      commit('setLoginApiStatus', 'success')
    } else {
      commit('setLoginApiStatus', 'failed')
      // dispatch('userLogout')
      commit('setLogout', true)
      localStorage.removeItem('isAuthenticated')
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
      commit('clearTokenData')
      // commit('setLoginApiStatus', '')
    }
  },
  async userLogout({ commit }) {
    const response = await axios
      .get('/picapi/logout', {
        withCredentials: false,
        credentials: 'include',
      })
      .catch((err) => {
        console.log(err)
      })

    if (response && response.data) {
      commit('setLogout', true)
      localStorage.removeItem('isAuthenticated')
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
    } else {
      commit('setLogout', true)
    }

    commit('clearTokenData')
    commit('setLoginApiStatus', '')
  },
  async userProfile({ commit, state }) {
    const response = await axios
      .post(
        '/picapi/user-profile',
        { email: state.authData.email },
        {
          withCredentials: false,
          credentials: 'include',
          headers: {
            'x-access-token': localStorage.getItem('access_token'),
          },
        }
      )
      .catch((err) => {
        console.log(err)
      })

    if (response && response.data) {
      commit('setUserProfile', response.data)
    }
  },
  async deleteMeUser({ commit }, payload) {
    await axios
      .post(
        '/picapi/delete-me', payload, {
        withCredentials: false,
        credentials: 'include',
      })
      .catch((err) => {
        console.log('err:', err)
      })
    commit('setRegisterMessage', 'Check deine Mail.')
  },
  async registerUser({ commit }, payload) {
    const response = await axios
      .post(
        '/picapi/register', payload, {
        withCredentials: false,
        credentials: 'include',
      })
      .catch((err) => {
        console.log('err:', err)
      })
    console.log('setRegisterMessage', response.data)
    commit('setRegisterMessage', response.data.message)
  },
}

const mutations = {
  clearTokenData(state) {
    state.authData = {
      token: '',
      accessToken: '',
      refreshToken: '',
      tokenExp: '',
      userId: '',
      userName: '',
      memberSince: '',
      sentForPrint: false,
      email: '',
    }
  },
  saveTokenData(state, data) {
    if (data.access_token != 'undefined' && data.access_token != 'null') {
      localStorage.setItem('access_token', data.access_token)
      localStorage.setItem('refresh_token', data.refresh_token)

      const jwtDecodedValue = jwtDecrypt(data.access_token)
      const newTokenData = {
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
        tokenExp: jwtDecodedValue.exp,
        userId: jwtDecodedValue.sub,
        userName: jwtDecodedValue.userName,
        blockName: jwtDecodedValue.blockName,
        memberSince: jwtDecodedValue.memberSince,
        sentForPrint: jwtDecodedValue.sentForPrint,
        userRole: jwtDecodedValue.role,
        email: jwtDecodedValue.email,
      }
      state.authData = newTokenData
    }
  },
  setRegisterMessage(state, data) {
    state.registerMessage = data
  },
  setLoginApiStatus(state, data) {
    state.loginApiStatus = data
  },
  setLogout(state, payload) {
    state.logOut = payload
    const userProfile = {
      id: 0,
      lastName: '',
      firstName: '',
      memberSince: '',
      sentForPrint: false,
      email: '',
      phone: '',
      defaultWeekWorktimeInMinutes: '',
      defaultWeekWorkdays: '',
    }
    state.userProfile = userProfile
  },
  setUserProfile(state, data) {
    const userProfile = {
      id: data.id,
      lastName: data.last_name,
      firstName: data.first_name,
      memberSince: data.member_since,
      sentForPrint: data.sent_for_print,
      email: data.email,
      phone: data.phone,
      defaultWeekWorktimeInMinutes: data.default_week_worktime_in_minutes,
      defaultWeekWorkdays: data.default_week_workdays,
      settings: data.settings,
    }
    state.userProfile = userProfile
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
