import axios from "axios"

if (process.env.NODE_ENV !== 'production') {
  axios.defaults.baseURL = 'http://localhost:3032'
  // axios.defaults.baseURL = 'http://localhost:' + process.env.VUE_APP_API_PORT;
  // } else {
  //   axios.defaults.baseURL = 'http://localhost:3002';
}

const state = () => ({
  uploadPicData: null,
  frontPic: null,
  backPic: null,
})

const getters = {
  getUploadPicData(state) {
    return state.uploadPicData
  },
  getFrontPic(state) {
    return state.frontPic
  },
  getBackPic(state) {
    return state.backPic
  },
}

const actions = {
  async clearUploadPicData({ commit }) {
    commit("setUploadPicData", null)
  },
  async downloadFront({ commit }, payload) {
    const response = await axios
      .get("/picapi/downloadPicFront/" + payload.userId, {
        withCredentials: false,
        credentials: "include",
        responseType: "blob",
        headers: {
          "x-access-token": localStorage.getItem("access_token"),
        },
      })
      .catch((err) => {
        console.log(err)
      })

    if (response) {
      var reader = new window.FileReader()
      reader.readAsDataURL(response.data)
      reader.onload = function () {
        var imageDataUrl = reader.result

        commit('setFrontPic', imageDataUrl)
      }
    }
  },
  async downloadBack({ commit }, payload) {
    const response = await axios
      .get("/picapi/downloadPicBack/" + payload.userId, {
        withCredentials: false,
        credentials: "include",
        responseType: "blob",
        headers: {
          "x-access-token": localStorage.getItem("access_token"),
        },
      })
      .catch((err) => {
        console.log(err)
      })

    if (response) {
      var reader = new window.FileReader()
      reader.readAsDataURL(response.data)
      reader.onload = function () {
        var imageDataUrl = reader.result

        commit('setBackPic', imageDataUrl)
      }
    }
  },
  async orderClubcard(_, payload) {
    const response = await axios
      .post('/picapi/order', payload, {
        withCredentials: false,
        credentials: "include",
        headers: {
          "x-access-token": localStorage.getItem("access_token"),
        },
      })
      .catch((err) => {
        console.log(err)
      })

    if (response && response.data) {
      console.log(response.data)
    }
  },
  async uploadPic({ commit }, payload) {
    const response = await axios
      .post("/picapi/uploadPic", payload, {
        withCredentials: false,
        credentials: "include",
        headers: {
          "x-access-token": localStorage.getItem("access_token"),
        },
      })
      .catch((err) => {
        console.log(err)
      })

    if (response && response.data) {
      commit("setUploadPicData", response.data)
    }
  },
}

const mutations = {
  setUploadPicData(state, data) {
    state.uploadPicData = data
  },
  setFrontPic(state, data) {
    state.frontPic = data
  },
  setBackPic(state, data) {
    state.backPic = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
