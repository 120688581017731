<template>
  <div class="flex">
    <Help />
  </div>
</template>

<script>
import Help from "vue-material-design-icons/Help.vue";

export default {
  name: "HelpIcon",
  components: {
    Help,
  },
};
</script>
