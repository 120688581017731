<template>
  <div>
    <ul class="sticky inset-x-0 top-0 left-0 bg-[#e5e7eb] z-50 py-1 flex">
      <li
        v-if="getUserProfile.id == 0"
        class="mr-6"
      >
        <router-link
          to="/login"
          class="ext-blue-500 hover:text-blue-800"
        >
          Login
        </router-link>
      </li>
      <li class="mr-6">
        <span
          class="text-blue-500 hover:text-blue-800"
          @click="logout()"
        >
          <IconSelector icon-text="logoutVariant" />
        </span>
      </li>
      <li
        v-if="getAuthData.userRole == 'admin'"
        class="mr-6"
      >
        <router-link
          to="/home"
          class="text-blue-500 hover:text-blue-800"
        >
          <IconSelector icon-text="homeOutline" />
        </router-link>
      </li>
      <li
        v-if="getAuthData.userRole == 'admin'"
        class="mr-6"
      >
        <router-link
          to="/admin"
          class="text-blue-500 hover:text-blue-800"
        >
          <IconSelector icon-text="accountSettingsOutline" />
        </router-link>
      </li>
    </ul>
    <div class="pt-1">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import IconSelector from './components/combinedIcons/IconSelector.vue'

export default {
  name: 'App',
  components: { IconSelector },
  computed: {
    ...mapGetters('auth', {
      getUserProfile: 'getUserProfile',
      getLogout: 'getLogout',
      getAuthData: 'getAuthData',
    }),
  },
  created() {
    this.checkStoredToken()
  },
  methods: {
    ...mapActions('auth', {
      userLogout: 'userLogout',
      checkStoredToken: 'checkStoredToken',
    }),
    ...mapMutations('auth', {
      setLogout: 'setLogout',
      setUserProfile: 'setUserProfile',
    }),
    async logout() {
      await this.userLogout()
      if (this.getLogout) {
        const resetUser = {
          id: 0,
          lastName: '',
          firstName: '',
          email: '',
          blockName: '',
          memberSince: '',
          phone: '',
        }
        this.setUserProfile(resetUser)
        this.setLogout(true)
        this.$router.push('/login')
      }
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
