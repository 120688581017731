<template>
  <div class="flex">
    <div class="w-1/2">
      &#189;
    </div>
    <div class="w-1/2">
      <BriefcaseIcon />
    </div>
  </div>
</template>

<script>
import BriefcaseIcon from "vue-material-design-icons/Briefcase.vue";

export default {
  name: "HalfWorkdayIcon",
  components: {
    BriefcaseIcon,
  },
};
</script>
