<template>
  <div class="flex">
    <CalendarArrowRight />
  </div>
</template>

<script>
import CalendarArrowRight from "vue-material-design-icons/CalendarArrowRight.vue";

export default {
  name: "CalendarArrowRightIcon",
  components: {
    CalendarArrowRight,
  },
};
</script>
