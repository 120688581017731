import axios from "axios"

if (process.env.NODE_ENV !== 'production') {
  axios.defaults.baseURL = 'http://localhost:3032'
  // axios.defaults.baseURL = 'http://localhost:' + process.env.VUE_APP_API_PORT;
  // } else {
  //   axios.defaults.baseURL = 'http://localhost:3002';
}

const state = () => ({
  users: [],
})

const getters = {
  users(state) {
    return state.users;
  },
}

const actions = {
  async getUsers({ commit }) {
    const response = await axios
      .get("/picapi/users", {
        withCredentials: false,
        credentials: "include",
        headers: {
          "x-access-token": localStorage.getItem("access_token"),
        },
      })
      .catch((err) => {
        console.log(err)
      })

    if (response && response.data) {
      commit("setUsers", response.data)
    }
  },
  async toggleUserStatus({ commit }, payload) {
    const response = await axios
      .post("/picapi/users/toggleUserStatus", payload, {
        withCredentials: false,
        credentials: "include",
        headers: {
          "x-access-token": localStorage.getItem("access_token"),
        },
      })
      .catch((err) => {
        console.log(err)
      })

    if (response && response.data) {
      commit("setUser", response.data)
    }
  },
  async toggleUserIgnore({ dispatch }, payload) {
    const response = await axios
      .post("/picapi/users/toggleUserIgnore", payload, {
        withCredentials: false,
        credentials: "include",
        headers: {
          "x-access-token": localStorage.getItem("access_token"),
        },
      })
      .catch((err) => {
        console.log(err)
      })

    if (response && response.data) {
      dispatch("getUsers")
    }
  },
  async setUserPassword(_, payload) {
    await axios
      .post('/picapi/users/userPassword', payload, {
        withCredentials: false,
        credentials: 'include',
        headers: {
          'x-access-token': localStorage.getItem('access_token'),
        },
      })
      .catch((err) => {
        console.log(err)
      })
  },
  async deleteUser({ dispatch }, payload) {
    const response = await axios
      .post("/picapi/users/deleteUser", payload, {
        withCredentials: false,
        credentials: "include",
        headers: {
          "x-access-token": localStorage.getItem("access_token"),
        },
      })
      .catch((err) => {
        console.log(err)
      })

    if (response && response.data) {
      dispatch("getUsers")
    }
  },
}

const mutations = {
  setUsers(state, data) {
    state.users = data
  },
  setUser(state, data) {
    let oldData = state.users
    const index = oldData.findIndex((e) => e.id == data.id)
    if (index != -1) {
      oldData[index] = data
      state.users = oldData
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
