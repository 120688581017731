<template>
  <div class="flex">
    <HomeOutline />
  </div>
</template>

<script>
import HomeOutline from "vue-material-design-icons/HomeOutline.vue";

export default {
  name: "HomeOutlineIcon",
  components: {
    HomeOutline,
  },
};
</script>
