<template>
  <div class="flex">
    <ArrowTopLeft />
  </div>
</template>

<script>
import ArrowTopLeft from "vue-material-design-icons/ArrowTopLeft.vue";

export default {
  name: "ArrowTopLeftIcon",
  components: {
    ArrowTopLeft,
  },
};
</script>
